<template>
    <div id="contact">
        <div class="container-fluid contact-margin">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15866.08340003827!2d106.864569!3d-6.194796!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa8650b49461bab06!2sPOLARGATE%20STUDIO!5e0!3m2!1sid!2sid!4v1637301951299!5m2!1sid!2sid" width="100%" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      <div class="container py-5 my-5">
        <div class="row my-5 ">
            <div class=" col-12 text-center garis">
                <h3 class="tittle-contact">CONTACT US</h3>
               <center> <hr style="border-top: 4px solid #058CFF; width: 90px; opacity: 100;"></center>
            </div>
        </div>
        <div class="row mt-3 text-center">
            <div class="col-12">
                <h5>Call us today to see how we can help you on your next project. </h5>
            </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-4 mt-3">
                <div class="mt-5 pt-5 form-1">
                  <h4 >Let’s Talk About Business</h4>
                </div>
                <div class="form-floating mt-4 mb-2">
                  <input type="text" class="form-control" style="border-radius: 9px;" id="floatingInput"  placeholder="Your Name">
                  <label for="floatingInput">Name *</label>
                </div>
                <div class="form-floating mb-2">
                  <input type="text" class="form-control" style="border-radius: 9px;" id="floatingInput"  placeholder="Your Name">
                  <label for="floatingInput">Company Name *</label>
                </div>
                <div class="form-floating mb-2">
                  <input type="email" class="form-control" style="border-radius: 9px;" id="floatingInput"  placeholder="Email">
                  <label for="floatingInput">Email *</label>
                </div>
                <div class="form-floating mb-2">
                  <input type="number" class="form-control" style="border-radius: 9px;" id="floatingInput" placeholder="Phone Number">
                  <label for="floatingInput">Mobile Phone *</label>
                </div>
                <div class="form-floating mb-2 " >
                  <textarea class="form-control"  placeholder="Your Message *" id="floatingTextarea2" style="height: 120px; border-radius: 9px;"></textarea>
                  <label for="floatingTextarea2">Message *</label>
                </div>
                <button class="btn btn-primary w-100 btn-lg" style="border-radius: 9px;">Submit</button>
          </div>
          <div class="col-12 col-lg-2"></div>
          <div class="col-lg-4 col-12 col-md-12 mt-5 loc-contact" >
            <div class="row" >
              <div class="col-12" style="background : #058CFF; border-radius: 13px;">
                <div class="my-5 mx-5">
                  <div class="row">
                    <div class="col-12">
                      <strong><h5 class="my-2 ">Office</h5></strong>
                      <!-- <img src="../assets/images/icon/Asset 714.png" alt=""><p> Jl. Kemuning No.5 Utan Kayu Utara. <br> Jakarta Timur. 13120</p> -->
                        <a href="https://www.google.com/maps/uv?pb=!1s0x2e69f45ff0824589%3A0xa8650b49461bab06!3m1!7e115!4s%2Fmaps%2Fplace%2Fpolargate%2Bstudio%2F%40-6.1947279%2C106.8645944%2C3a%2C75y%2C200.33h%2C90t%2Fdata%3D*213m4*211e1*213m2*211s5LoYYcg1XAx3ZQoqFOEDBQ*212e0*214m2*213m1*211s0x2e69f45ff0824589%3A0xa8650b49461bab06%3Fsa%3DX!5spolargate%20studio%20-%20Google%20Nggoleki!15sCgIgAQ&imagekey=!1e2!2s5LoYYcg1XAx3ZQoqFOEDBQ&hl=jv&sa=X&sqi=2&ved=2ahUKEwjo3-fewrL0AhXQgVwKHVY5By0Qpx96BAhAEAM" class="font-list" style="text-decoration: none;">
                          <div class="row font-list mt-1" style="line-height: 32px">
                                <div class="col-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                                    </svg>
                                </div>
                                <div class="col-10 col-lg-11">
                                  Jl. Kemuning No.5 Utan Kayu Utara. <br> Jakarta Timur. 13120
                                </div>
                          </div>
                        </a>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12 ">
                      <strong><h5 class="mb-2 mt-3" >Contact Us</h5></strong>
                        <a href="https://api.whatsapp.com/send?phone=6281280075188&text=Halo,%20Saya%20Tertarik%20Dengan%20Polargate%20Studio%20dari%20Website" class="font-list" style="text-decoration: none;"> 
                          <div class="row font-list mt-1 " style="line-height: 32px">
                              <div class="col-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                  </svg>
                              </div>
                              <div class="col-10 col-lg-11">
                                  0812 80075188
                              </div>
                          </div>
                        </a>
                        <div class="row font-list mt-1" style="line-height: 32px">
                            <div class="col-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                </svg>
                            </div>
                            <div class="col-10 col-lg-11">
                               info@polargate-studio.com
                            </div>
                          </div>
                        <a href="https://www.instagram.com/polargatestudio/" class="font-list" style="text-decoration: none;">
                          <div class="row font-list mt-1" style="line-height: 32px">
                              <div class="col-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                                </svg>
                              </div>
                              <div class="col-10 col-lg-11">
                                  @polargatestudio
                              </div>
                          </div>
                        </a>
                        <a href="https://www.facebook.com/polargatestudio/" class="font-list" style="text-decoration: none;"> 
                          <div class="row font-list mt-1" style="line-height: 32px">
                              <div class="col-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                  </svg>
                              </div>
                              <div class="col-10 col-lg-11">
                              Polargate Studio
                              </div>
                          </div>
                        </a>

                     <!-- <p> <img src="../assets/images/icon/Asset 814.png">0812 80075188</p>
                     <p> <img src="../assets/images/icon/Asset 914.png">info@polargate-studio.com</p>
                     <p> <img src="../assets/images/icon/Asset 1014.png">@polargatestudio</p>
                     <p> <img src="../assets/images/icon/Asset 1114.png">Polargate Studio</p> -->
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 ">
                <h5 class="title-telUs">TELL US ABOUT YOUR PROJECT</h5>
                <h6 class="telUs">We'd love to know how we can help you and love to hearing from new people. Wel'll get back to you as soon as we can.</h6>
              </div>
            </div>
              
          </div>
          
        </div>
      </div>
    </div>
    </div>
</template>

<style scoped>
.form-1{
  margin-top: 800px;
}

.tittle-contact{
    font-family: 'Avenir LT Std';
    color: #058CFF;
    font-weight: 900;
}

.loc-contact{
  font-family: 'Avenir LT Std';
    color: #FFFFFF;
    font-weight: 300;
}

.telUs{
  font-family: 'Gotham*';
  color:#000000;
  font-weight: 400;
}

.title-telUs{
  font-family: 'Gotham*';
  color:#000000;
  font-weight: 800;
}

.font-list{
  font-family: 'Avenir LT Std';
    color: #FFFFFF;
    font-weight: 300;
}


.contact-margin{
   padding-top: 82px;
}

@media only screen and (max-width: 1000px) {
    .contact-margin{
    padding-top: 95px;
    }
}
@media only screen and (max-width: 700px) {
    .contact-margin{
    padding-top: 82px;
    }
}
</style>

<script>

export default {
    mounted () {
    window.scrollTo(0, 0)
  },
}
</script>
